import { getGa4DefaultParams } from "./common";

const REG_TYPE_TRACK_KEY = "ga4_reg_type";

export function getGa4OrganisationRegistrationParams(registrationType?: string): Gtag.CustomParams {
  let trackingRegType = registrationType ? registrationType : null;
  if (window.sessionStorage) {
    if (registrationType) {
      // Should be provided in first call if specific value - throughout e.g. a flow
      window.sessionStorage.setItem(REG_TYPE_TRACK_KEY, registrationType);
    }

    trackingRegType = window.sessionStorage.getItem(REG_TYPE_TRACK_KEY);
  }

  return {
    ...getGa4DefaultParams(),
    registration_type: trackingRegType ? trackingRegType : "default",
  };
}
