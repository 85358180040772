export function getGa4DefaultParams(): Gtag.CustomParams {
  return {
    business_area: "b2b",
    page_segment: "mybusiness",
  };
}

export function getGa4StepParams(
  stepLabel?: string,
  stepNumber?: number,
  stepOption?: string
): Gtag.CustomParams {
  return {
    step_label: stepLabel,
    step_number: stepNumber,
    step_option: stepOption,
  };
}
