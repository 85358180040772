import { getGa4DefaultParams } from "../b2b-analytics";

export function getGa4ManageInviteParams(): Gtag.CustomParams {
  return {
    ...getGa4DefaultParams(),
    page_type: "manage",
    logged_in: true,
  };
}

const LOGGED_IN_TRACK_KEY = "ga4_logged_in";

export function getGa4InviteUserAccountParams(loggedIn?: boolean): Gtag.CustomParams {
  let trackingLoggedIn: boolean = !!loggedIn;
  if (window.sessionStorage) {
    if (typeof loggedIn !== "undefined") {
      // Should be provided in first call if specific value - throughout e.g. a flow
      window.sessionStorage.setItem(LOGGED_IN_TRACK_KEY, `${loggedIn}`);
    }

    const val = window.sessionStorage.getItem(LOGGED_IN_TRACK_KEY); // "true", "false", null
    trackingLoggedIn = val === "true";
  }

  return {
    ...getGa4DefaultParams(),
    page_type: "profile",
    logged_in: trackingLoggedIn,
  };
}
