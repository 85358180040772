import { track } from "@telia/b2x-google-analytics";
import { TeliaEventName, TeliaEventParams } from "@telia/b2x-google-analytics";

/**
 * function that wraps the b2x-google-analytics track function
 * @param eventName Typically a string that needs to be defined as type in b2x-google-analytics
 * @param eventParams An object holding various properties with values
 */
export function trackGa4(eventName: TeliaEventName, eventParams: TeliaEventParams): void {
  return track(eventName, eventParams);
}

export * from "./helper/common";
export * from "./helper/accountRegistration";
export * from "./helper/invite";
